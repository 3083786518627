import { useState, KeyboardEvent, useRef } from 'react';
import { io, Socket } from 'socket.io-client';
import { PaperAirplaneIcon } from '@heroicons/react/24/solid';
import { useMediaQuery } from 'react-responsive';

import Markdown from './components/Markdown';
import Textarea from './components/Textarea';

enum Model {
  GEMINI_FLASH = 'GEMINI_FLASH',
  GPT_MINI = 'GPT_MINI',
  GEMINI = 'GEMINI',
  GPT = 'GPT',
}

export default function App() {
  const [prompt, setPrompt] = useState('');
  const [response, setResponse] = useState('');
  const [shouldFormatResponse, setShouldFormatResponse] = useState(true);
  const [model, setModel] = useState(Model.GPT_MINI);
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });

  const socketRef = useRef<{
    socket: Socket;
    socketCleanupTimeout: NodeJS.Timeout;
  }>();

  const loadResponse = async () => {
    if (socketRef.current) {
      const { socket, socketCleanupTimeout } = socketRef.current;
      clearTimeout(socketCleanupTimeout);
      socket.close();
    }

    const SOCKET_URL =
      process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:5000/' : '/';

    const socket = io(SOCKET_URL);
    const socketCleanupTimeout = setTimeout(() => {
      socket.close();
    }, 25 * 1000);

    socketRef.current = { socket, socketCleanupTimeout };

    setResponse('...');

    socket
      .on('connect', () => {
        socket.emit('prompt', prompt, model);
      })
      .on('response', (chunk: string) => {
        const beginningOfResponse = (chunk: string) => chunk === '';
        const endOfResponse = (chunk: string) => chunk === undefined;

        if (beginningOfResponse(chunk)) {
          setResponse('');
        } else if (!endOfResponse(chunk)) {
          setResponse(response => response + chunk);
        } else {
          socket.close();
        }
      })
      .on('error', error => {
        setResponse(error);
        socket.close();
      });
  };

  const toggleResponseFormatting = () =>
    setShouldFormatResponse(!shouldFormatResponse);

  const clearScreen = () => {
    setPrompt('');
    setResponse('');
  };

  const handleKeyMapping = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.ctrlKey) {
      switch (event.key) {
        case 'Enter':
          event.preventDefault();
          loadResponse();
          return;
        case 'f':
          toggleResponseFormatting();
          return;
        case 'd':
          clearScreen();
          return;
        case '1':
          setModel(Model.GPT_MINI);
          return;
        case '2':
          setModel(Model.GPT);
          return;
        case '3':
          setModel(Model.GEMINI_FLASH);
          return;
        case '4':
          setModel(Model.GEMINI);
          return;
        default:
      }
    }
  };

  return isPortrait ? (
    <div
      className="flex h-screen flex-col space-y-4 p-4 text-gray-300"
      onKeyDown={handleKeyMapping}
    >
      <div
        tabIndex={0}
        className="neu-up h-3/5 flex-grow overflow-auto rounded-lg p-4"
      >
        <Markdown content={response} shouldFormat={shouldFormatResponse} />
      </div>
      <div className="flex items-center justify-between">
        <select
          tabIndex={0}
          value={model}
          onChange={e => setModel(e.target.value as Model)}
          className="neu-down appearance-none p-2"
        >
          <option value={Model.GPT_MINI}>GPT-4o mini</option>
          <option value={Model.GPT}>GPT-4o</option>
          <option value={Model.GEMINI_FLASH}>Gemini-1.5-Flash</option>
          <option value={Model.GEMINI}>Gemini-1.5-Pro</option>
        </select>
        <button
          tabIndex={0}
          onClick={loadResponse}
          className="neu-up rounded-full p-2"
        >
          <PaperAirplaneIcon className="size-4" />
        </button>
      </div>
      <div className="neu-down flex-grow rounded-lg p-4">
        <Textarea value={prompt} setValue={value => setPrompt(value)} />
      </div>
    </div>
  ) : (
    <div
      className="flex h-screen w-screen p-4 text-gray-200"
      onKeyDown={handleKeyMapping}
    >
      <div className="flex w-2/5 flex-col m-2">
        <div className="neu-down flex-grow rounded-lg p-4">
          <Textarea value={prompt} setValue={value => setPrompt(value)} />
        </div>
        <div className="flex items-center justify-between mt-4">
          <select
            tabIndex={0}
            value={model}
            onChange={e => setModel(e.target.value as Model)}
            className="neu-down appearance-none p-3 px-5"
          >
            <option value={Model.GPT_MINI}>GPT-4o mini</option>
            <option value={Model.GPT}>GPT-4o</option>
            <option value={Model.GEMINI_FLASH}>Gemini-1.5-Flash</option>
            <option value={Model.GEMINI}>Gemini-1.5-Pro</option>
          </select>
          <button
            tabIndex={0}
            onClick={loadResponse}
            className="neu-up rounded-full p-3"
          >
            <PaperAirplaneIcon className="size-5" />
          </button>
        </div>
      </div>
      <div
        tabIndex={0}
        className="neu-up w-3/5 flex-grow overflow-auto rounded-lg m-2 ml-8 p-4"
      >
        <Markdown content={response} shouldFormat={shouldFormatResponse} />
      </div>
    </div>
  );
}
